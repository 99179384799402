import * as emojiData from '../emojis.json'
import React, { useEffect, useState, useCallback } from 'react'
import firebase from './firebase.js'
import logger from './logger.js'

const chooseRandom = (list) => {
  return list[Math.floor(Math.random() * list.length)]
}

const sanitizeInput = (input) => {
  const sanitizedInput = input
    .replace('  ', ' ')
    .replace(/[^A-Za-z0-9 '’,#*"ô.éã():-]/g, '')
    .toLowerCase()
  
  return sanitizedInput
}

const App = () => {
  const emojis = emojiData.emojis.filter(emoji => {
    if (!emoji.fullyQualified) return false
    if (emoji.name.includes('tone')) return false
    if (emoji.group === 'Flags' && Math.random() > .25) return false
    if (emoji.group === 'Symbols' && emoji.name.includes('Japanese') && Math.random() > .25) return false
    return true
  })
  const [currentEmoji, setCurrentEmoji] = useState(chooseRandom(emojis))
  const [currentEmojiCounts, setCurrentEmojiCounts] = useState([])
  const [currentEmojiCountsTotal, setCurrentEmojiCountsTotal] = useState(0)
  const [currentView, setCurrentView] = useState('prompt')
  const [answer, setAnswer] = useState('')
  const [isFirstToAnswer, setIsFirstToAnswer] = useState(false)
  const db = firebase.firestore()

  const focusRef = useCallback(el => {
    if (el !== null) {
      el.focus();
    }
  }, []);

  useEffect(() => {
    logger('emoji-project v0.7', 'emojis loaded: ' + emojis.length)
  }, [])

  const handleChange = (e) => {
    let value = e.target.value
     
    value = sanitizeInput(value)

    setAnswer(value)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmitClick()
    }
  }

  const getResults = (emojiName) => {
    const countRef = db.collection('counts').doc(emojiName)

    countRef.get().then(count => {
      if (count.exists) {
        const data = count.data()
        logger('Document data:', count.data())

        const counts = Object
          .entries(data)
          .filter(entry => entry[0] !== 'null')
          .map(entry => ({ answer: entry[0], count: entry[1] }))
          .sort((a, b) => a.count < b.count)
          .slice(0, 5)

        const total = counts.reduce((result, current) => result + current.count, 0)

        setCurrentEmojiCounts(counts)
        setCurrentEmojiCountsTotal(total)
        setIsFirstToAnswer(false)
      } else {
        logger('no such document in counts collection')
        setIsFirstToAnswer(true)
      }
    }).catch(function (error) {
      logger('Error getting document:', error)
    })    
  }

  const sendAnswer = async (answer) => {
    logger('sending answer', answer)

    const responseRef = db.collection('responses').doc()

    try {
      await responseRef.set(answer)

      const response = await fetch(`/api`, {
        headers: { 'Content-Type': 'application/json' },
        method: 'post',
        body: JSON.stringify(answer)
      })

      const data = await response.json()

      logger('sent to api', data)
    } catch (error) {
      logger('submission failed', error)
    }
  }

  const handleSubmitClick = () => {
    setAnswer(sanitizeInput(answer))

    if (answer === '') {
      return
    }

    logger('submit event', answer)

    // send submission
    sendAnswer({
      prompt: currentEmoji.name,
      answer: answer,
      date: firebase.firestore.FieldValue.serverTimestamp(),
      skipped: false,
      displayIssue: false
    })

    getResults(currentEmoji.name)

    // switch view
    setCurrentView('result')
  }

  const handleNextClick = () => {
    setAnswer('')
    setCurrentEmoji(chooseRandom(emojis))
    setCurrentEmojiCounts([])
    setCurrentEmojiCountsTotal(0)
    setIsFirstToAnswer(false)
    setCurrentView('prompt')
  }

  const handleSkipClick = () => {
    setAnswer('')
    logger('submit event', answer)

    // send submission
    sendAnswer({
      prompt: currentEmoji.name,
      answer: null,
      date: firebase.firestore.FieldValue.serverTimestamp(),
      skipped: true,
      displayIssue: false
    })

    setCurrentView('result')
  }

  const handleDisplayIssueClick = () => {
    setAnswer('')
    logger('submit event', answer)

    // send submission
    sendAnswer({
      prompt: currentEmoji.name,
      answer: null,
      date: firebase.firestore.FieldValue.serverTimestamp(),
      skipped: false,
      displayIssue: true
    })

    setCurrentView('result')
  }

  return (
    <>
      <header>
        <div className='container'>
          <h1>Emoji Project <div className='badge space-left-1'>BETA</div></h1>
        </div>
      </header>
      <main>
        <div className='container'>
          <div className='emoji'>
            {currentEmoji.display}
          </div>
          {currentView === 'prompt' &&
            <div>
              <p>What would you call this emoji?</p>
              <input
                autoComplete='off'
                autoCorrect='off'
                autoCapitalize='none'
                maxLength='36'
                onChange={handleChange}
                onKeyPress={handleKeyPress}
                ref={focusRef}
                type='text'
                value={answer}
              />
              <div className='buttons'>
                <button onClick={handleSubmitClick}>Submit</button>
                <button onClick={handleSkipClick}>Skip!</button>
                <button onClick={handleDisplayIssueClick}>This emoji looks broken</button>
              </div>
            </div>}
          {currentView === 'result' &&
            <div>
              <p>This emoji is called:</p>
              <h2>{currentEmoji.name}</h2>
              {answer &&
                <>
                  <p>You called it:</p>
                  <h2>{answer}</h2>
                  {currentEmoji.name === answer &&
                    <div className='badge space-below-1'>Nice guess!</div>
                  }
                  {isFirstToAnswer &&
                    <div className='badge space-below-1'>First to answer!</div>
                  }
                </>
              }
              <p>This is what others call it:</p>
              {currentEmojiCountsTotal > 0 ? (
                <>
                  <ul className='result-list'>
                    {currentEmojiCounts.map(currentEmojiCount => (
                      <li key={currentEmojiCount.answer} style={{ width: currentEmojiCount.count / currentEmojiCountsTotal * 100 + '%' }}>
                        {currentEmojiCount.answer} ({(currentEmojiCount.count / currentEmojiCountsTotal * 100).toPrecision(3) + '%'})
                      </li>
                    ))}
                  </ul>
                  <p className='total-answers'>Total answers by others: {currentEmojiCountsTotal}</p>
                </>
              ) : (
                <p className='note'>No one else has named this emoji yet.</p>
              )}
              <button onClick={handleNextClick} ref={focusRef}>Next one!</button>
            </div>}
        </div>
      </main>
      {/* <footer>
        <div className='badge'>BETA</div>
        Emoji Project &copy; 2020
      </footer> */}
    </>
  )
}

export default App
