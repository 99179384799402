import * as firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDT6fxoajPI1Zo1mLBbb7MP_a3yQir9EaI',
  authDomain: 'emoji-project-d1e76.firebaseapp.com',
  databaseURL: 'https://emoji-project-d1e76.firebaseio.com',
  projectId: 'emoji-project-d1e76',
  storageBucket: 'emoji-project-d1e76.appspot.com',
  messagingSenderId: '16630354708',
  appId: '1:16630354708:web:b53980aa35748f9aec6801'
}

firebase.initializeApp(firebaseConfig)

export default firebase
